<template>
  <div id="index">
    <header>
      <div class="saleroom" v-if="head_saleroom">
        <h6>当前运营额</h6>
        <p class="amount">{{ head_saleroom.currentSales?.toLocaleString() }}</p>
        <ul class="data">
          <li>
            <span>周同比</span>
            <i
              class="iconfont icon-shangjiantou"
              v-if="head_saleroom.weekContrast > 0"
            ></i>
            <i class="iconfont icon-xiajiantou" v-else></i>
            <span>{{ head_saleroom.weekContrast }}%</span>
          </li>
          <li>
            <span>日环比</span>
            <i
              class="iconfont icon-shangjiantou"
              v-if="head_saleroom.dayContrast > 0"
            ></i>
            <i class="iconfont icon-xiajiantou" v-else></i>
            <span>{{ head_saleroom.dayContrast }}%</span>
          </li>
        </ul>
        <div class="lineBetween"></div>
        <div class="count">
          <span>平台累计</span> ¥{{
            head_saleroom.totalSales?.toLocaleString()
          }}
        </div>
      </div>
      <div class="head_visitChart">
        <h6>车辆日访问量</h6>
        <p class="amount">{{ head_visit.today?.toLocaleString() }}</p>
        <echarts-com
          class="data"
          id="head_visitChart"
          :options="head_visit.options"
          style="background: transparent"
        />
        <div class="lineBetween"></div>
        <div class="count">
          <span>总访问量</span> {{ head_visit.total?.toLocaleString() }}
        </div>
      </div>
      <div class="malfunction">
        <h6>设备故障数</h6>
        <p class="amount">0</p>
        <div class="data">
          <el-progress
            :percentage="0"
            :stroke-width="10"
            color="#1890ff"
            :show-text="false"
          ></el-progress>
        </div>
        <div class="lineBetween"></div>
        <div class="count"><span>设备故障率</span> 0%</div>
      </div>
    </header>
    <div class="sale">
      <div class="parkingLotFiltrate">
        <div class="type">
          <span
            @click="saleTypeChange('运营额')"
            :class="{ active: parkingLotFiltrate.category === '运营额' }"
            >运营额</span
          >
          <span
            @click="saleTypeChange('访问量')"
            :class="{ active: parkingLotFiltrate.category === '访问量' }"
            >访问量</span
          >
        </div>
        <ul class="filtrate">
          <li
            v-for="item of typeList"
            :key="item.value"
            :class="{ active: parkingLotFiltrate.type === item.value }"
            @click="changeParkingLot_dateType(item.value)"
          >
            {{ item.label }}
          </li>
          <!-- <li>
            <el-date-picker
              v-model="parkingLotFiltrate.date"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </li> -->
        </ul>
      </div>
      <div class="chartBox">
        <div class="chart" v-loading="parkingLotFiltrate.loading">
          <h4>{{ parkingLotFiltrate.category }}趋势</h4>
          <echarts-com id="parkingLotOptions" :options="parkingLotOptions" />
        </div>
        <div class="ranking" v-loading="parkingLotFiltrate.loading">
          <h4>停车场{{ parkingLotFiltrate.category }}排名</h4>
          <div class="listBox">
            <ul
              v-for="(item, index) of parkingLotRank"
              :key="item.parkingLotName"
            >
              <li
                class="ranking-img"
                v-if="index === 0 || index === 1 || index === 2"
              >
                <el-image
                  :src="require(`@/assets/images/ranking${index + 1}.png`)"
                  fit="contain"
                ></el-image>
              </li>
              <li v-else>{{ index + 1 }}</li>
              <li :title="item.parkingLotName">{{ item.parkingLotName }}</li>
              <li class="progress">
                <el-progress :percentage="item.proportion"></el-progress>
              </li>
              <li>{{ item.value?.toLocaleString() }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="count">
      <div class="payAmount">
        <h4>日支付笔数</h4>
        <echarts-com
          class="data"
          id="payAmountChart"
          :options="payAmount.options"
        />
      </div>
      <div class="pieChart">
        <h4>支付渠道类别占比</h4>
        <div class="payFiltrate">
          <ul>
            <li
              :class="{ active: pay.paymentChannelDate === item.value }"
              @click="
                pay.paymentChannelDate = item.value;
                getPayOptions();
              "
              v-for="item of typeList"
              :key="item.value"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <echarts-com
          v-loading="pay.loading"
          id="payChart"
          :options="pay.options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import echartsCom from "@/components/echartsCom";
import { formatRatio } from "@/utils/util";

export default {
  components: {
    echartsCom,
  },
  data() {
    return {
      //当前运营额
      head_saleroom: {},
      //车访问量
      head_visit: {
        today: 0,
        total: 0,
        options: {},
      },
      typeList: [
        {
          value: "今日",
          label: "今日",
        },
        {
          value: "本周",
          label: "本周",
        },
        {
          value: "本月",
          label: "本月",
        },
        {
          value: "本季",
          label: "本季",
        },
        {
          value: "今年",
          label: "今年",
        },
      ],
      //趋势图上面的过滤条件
      parkingLotFiltrate: {
        loading: false,
        category: "运营额",
        date: "",
        type: "今日",
      },
      //停车场趋势图
      parkingLotOptions: {},
      //停车场排名
      parkingLotRank: [],
      //支付笔数
      payAmount: {
        options: {},
      },
      pay: {
        loading: false,
        //支付环形图
        options: {},
        //支付环形图的过滤条件
        paymentChannelDate: "今日",
      },
    };
  },
  created() {
    this.getHead_saleroom();
    this.getHead_visitOptions();
    this.getPayAmountOptions();
    this.getParkingLotInfo();
    this.getPayOptions();
  },
  methods: {
    async getHead_saleroom() {
      try {
        let res = await this.$http.get("/home/sales/contrast");
        if (res.code === 0) {
          res.data.dayContrast = formatRatio(res.data.dayContrast);
          res.data.weekContrast = formatRatio(res.data.weekContrast);
          this.head_saleroom = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getPayAmountOptions() {
      try {
        let res = await this.$http.get("/home/pay/day");
        if (res.code === 0) {
          if (!res.data) {
            return;
          }
          let xAxis_data = [];
          let values = [];
          for (let item of res.data.payDate) {
            xAxis_data.push(item.date);
            values.push(item.payCount);
          }

          let options = {
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              data: xAxis_data,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#1d3f5c",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#2a4b6f",
                  type: "dashed",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#1d3f5c",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            grid: {
              left: "0%",
              width: "100%",
              height: "90%",
              bottom: "0%",
              containLabel: true, // 包含坐标轴中的文字
            },

            series: [
              {
                type: "bar",
                barMaxWidth: 25,
                itemStyle: {
                  barBorderRadius: [33, 33, 0, 0],
                  color: "#00cec0",
                },
                data: values,
              },
            ],
          };

          Object.assign(this.payAmount, {
            options,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getHead_visitOptions() {
      try {
        let res = await this.$http.get("/home/visit/day");
        if (res.code === 0) {
          if (!res.data) {
            return;
          }
          let xAxis_data = [];
          let values = [];
          for (let item of res.data.dateVisits) {
            xAxis_data.push(item.date);
            values.push(item.visits);
          }
          let options = {
            tooltip: {
              trigger: "axis",
              position: function (pt) {
                return [pt[0], "10%"];
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              axisLabel: {
                show: false,
              },
              data: xAxis_data,
            },
            yAxis: {
              type: "value",
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
            },
            grid: {
              width: "100%",
              left: "0",
            },
            series: [
              {
                type: "line",
                symbol: "none",
                sampling: "lttb",
                itemStyle: {
                  color: "#c091f9",
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#cd86f133",
                    },
                    {
                      offset: 1,
                      color: "#892cd94f",
                    },
                  ]),
                },
                data: values,
              },
            ],
          };
          Object.assign(this.head_visit, {
            today: res.data.today,
            total: res.data.total,
            options,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    saleTypeChange(typeName) {
      this.parkingLotFiltrate.category = typeName;
      this.getParkingLotInfo();
    },
    //改变停车场筛选条件的时间类型
    changeParkingLot_dateType(type) {
      if (this.parkingLotFiltrate.type === type) {
        return;
      }
      this.parkingLotFiltrate.type = type;
      this.getParkingLotInfo();
    },
    //获取运营额趋势图和排名
    async getParkingLotInfo() {
      let url = "/home/sales/rank";
      if (this.parkingLotFiltrate.category === "访问量") {
        url = "/home/visits/rank";
      }
      try {
        this.parkingLotFiltrate.loading = true;
        let res = await this.$http.post(url, {
          type: this.parkingLotFiltrate.type,
        });
        if (res.code === 0) {
          for (let item of res.data.rankList) {
            item.proportion = parseInt(item.proportion * 100);
          }
          this.parkingLotRank = res.data.rankList;
          let xAxis_data = [];
          let values = [];
          for (let item of res.data.trendList) {
            xAxis_data.push(item.date);
            values.push({
              value: item.value,
              proportion: parseInt(item.proportion * 100) + "%",
            });
          }
          this.$set(this, "parkingLotOptions", {
            tooltip: {
              trigger: "axis",
              extraCssText: "box-shadow: 0px 0px 13px rgba(0, 193, 153, 0.22);",
              padding: [15, 10],
              formatter: (params) => {
                return `<div>
                  <p>
                    <span>${params[0].axisValue}</span>
                  </p>
                  <p>
                    <span style="display: inline-block;width:10px;height:10px;border-radius: 50%;background: linear-gradient(360deg, #F9C253 0%, #FFBB00 100%);"></span>
                    <span>占比:</span>
                    <span>${params[0].data.proportion}</span>
                  </p>
                  <p>
                    <span style="display: inline-block;width:10px;height:10px;border-radius: 50%;background: linear-gradient(360deg, #12D5CE 0%, #12D5CE 0%, #2EB6CF 98%, #2EB6CF 100%);"></span>
                    <span>${this.parkingLotFiltrate.category}:</span>
                    <span>${params[0].data.value}</span>
                  </p>
                </div>`;
              },
            },
            xAxis: {
              type: "category",
              data: xAxis_data,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#1d3f5c",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#2a4b6f",
                  type: "dashed",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#1d3f5c",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            grid: {
              left: "0%",
              width: "100%",
              height: "90%",
              bottom: "0%",
              containLabel: true, // 包含坐标轴中的文字
            },
            series: [
              {
                type: "bar",
                barMaxWidth: 25,
                itemStyle: {
                  barBorderRadius: [33, 33, 0, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#00cec0" },
                    { offset: 1, color: "#9de5e0" },
                  ]),
                },
                data: values,
              },
            ],
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.parkingLotFiltrate.loading = false;
      }
    },
    //获取支付环形图
    async getPayOptions() {
      try {
        this.pay.loading = true;
        let res = await this.$http.post("/home/payment/channel", {
          type: this.pay.paymentChannelDate,
        });
        if (res.code === 0) {
          let total = 0;
          let data = res.data.map((item) => {
            total += item.payAmount;
            return {
              value: item.payAmount,
              percentage: formatRatio(item.proportion) + "%",
              name: item.platformType,
            };
          });
          this.$set(this.pay, "options", {
            tooltip: {
              trigger: "item",
            },
            color: ["#975fe4", "#38a0ff", "#0ad0c1"],
            legend: {
              type: "scroll",
              icon: "circle",
              orient: "vertical",
              right: 0,
              top: "center",
              itemWidth: 10,
              itemHeight: 10,
              formatter(labelName) {
                let item = data.find((item) => item.name === labelName);
                return `{name|${item.name}}{dividingLine||}{percentage|${
                  item.percentage
                }}{value|¥${item.value?.toLocaleString()}}`;
              },
            },
            textStyle: {
              rich: {
                name: {
                  width: 80,
                  color: "white",
                },
                dividingLine: {
                  color: "white",
                  width: 10,
                },
                percentage: {
                  color: "white",
                  width: 55,
                },
                value: {
                  color: "white",
                },
              },
            },
            series: [
              {
                type: "pie",
                radius: ["67%", "90%"],
                left: 0,
                right: "50%",
                top: 0,
                bottom: 0,
                avoidLabelOverlap: false,
                label: {
                  show: true,
                  position: "center",
                  color: "#4c4a4a",
                  formatter: (params) => {
                    return `{total|¥${parseInt(total)}}\n\n\r{active|运营额}`;
                  },
                  rich: {
                    total: {
                      fontSize: 20,
                      color: "white",
                    },
                    active: {
                      fontSize: 12,
                      color: "white",
                    },
                  },
                },
                emphasis: {
                  label: {
                    show: true,
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data,
              },
            ],
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.pay.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#index {
  header {
    display: flex;
    justify-content: space-between;

    & > div {
      flex: 1;
      margin-bottom: 20px;
      background-color: #001f46;
      margin-right: 20px;
      padding: 15px 15px 10px 15px;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0;
      }
    }

    .saleroom,
    .malfunction,
    .head_visitChart {
      font-size: 14px;

      h6 {
        font-weight: 0;
        color: white;
      }

      .amount {
        font-size: 30px;
        margin: 10px 0;
        font-weight: bold;
      }

      ul {
        display: flex;
        justify-content: space-between;
        color: #778aa2;

        li {
          i {
            color: $main-color;
            margin: 0 10px;
            font-size: 12px;
          }

          .icon-xiajiantou {
            color: rgb(255, 119, 1);
          }

          span {
            &:last-child {
              color: white;
              font-weight: 0;
            }
          }
        }
      }

      .lineBetween {
        width: 100%;
        height: 1px;
        background-color: #2e4869;
        margin: 10px 0;
      }

      .data {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;

        .el-progress {
          width: 100%;
        }

        #head_visitChart {
          width: 100%;
          height: 100%;
        }
      }

      .count {
        span {
          color: #778aa2;
          margin-right: 30px;
        }
      }
    }
  }

  .sale {
    padding: 15px;
    background-color: #001f46;
    .parkingLotFiltrate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #2f526a;
      margin-bottom: 20px;

      .type {
        span {
          position: relative;
          transition: all 0.3s;
          cursor: pointer;
          margin-right: 20px;
          color: white;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 4px;
            background-color: transparent;
            position: absolute;
            bottom: -10px;
            left: 0;
          }
        }

        .active {
          color: $main-color;

          &::after {
            background-color: $main-color;
          }
        }
      }

      .filtrate {
        display: flex;
        align-items: center;

        li {
          margin-right: 10px;
          cursor: pointer;
          transition: all 0.3s;
          color: white;
          position: relative;

          &::after {
            transition: all 0.3s;
            content: "";
            display: inline-block;
            width: 100%;
            height: 4px;
            background-color: transparent;
            position: absolute;
            bottom: -12px;
            left: 0;
          }

          .el-date-editor {
            .el-input__inner {
              height: 28px;
              line-height: 28px;
            }

            .el-input__prefix {
              .el-input__icon {
                line-height: 28px;
              }
            }
          }
        }

        .active {
          color: $main-color;

          &::after {
            background-color: $main-color;
          }
        }
      }
    }

    .chartBox {
      display: flex;
      justify-content: space-between;

      h4 {
        font-size: 14px;
        color: white;
      }

      .chart {
        width: 65%;

        #parkingLotOptions {
          width: 100%;
          height: 270px;
          background: transparent;
        }
      }

      .ranking {
        width: 32%;
        font-size: 14px;

        .listBox {
          ul {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            &:first-child {
              margin-top: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            li {
              flex: 1;
              text-align: center;
              @include overflow-ellipsis;

              &:first-child {
                flex: initial;
                display: inline-block;
                width: 23px;
                height: 23px;
                line-height: 23px;
                border-radius: 50%;
                background-color: #02425e;
                margin-right: 5px;
              }

              &:last-child {
                flex: 0.8;
              }

              &.ranking-img {
                background-color: initial;
              }

              .el-image {
                width: 23px;
                height: 23px;
              }

              .el-progress {
                .el-progress__text {
                  margin-left: 15px;
                  color: white;
                }
              }
            }

            .progress {
              flex: 1.1;
            }
          }
        }
      }
    }
  }

  & > .count {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    & > div {
      padding: 15px;
      box-sizing: border-box;
      background-color: #001f46;
    }

    h4 {
      font-size: 14px;
      color: white;
      margin-bottom: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #2f526d;
    }

    .payAmount {
      width: 55%;

      #payAmountChart {
        width: 100%;
        height: 240px;
        background: transparent;
      }
    }

    .pieChart {
      width: calc(45% - 20px);

      h4 {
        padding-bottom: 20px;
        border-bottom: 1px solid #2f526d;
        margin-bottom: 10px;
      }

      .payFiltrate {
        display: flex;
        justify-content: flex-end;

        & > ul {
          display: inline-block;
          border: 1px solid #13d1c4;
          border-radius: 6px;
          font-size: 14px;

          li {
            display: inline-block;
            padding: 8px 11px;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            transition: all 0.3s;
            color: white;
          }

          .active {
            color: white;
            background-color: #0ad0c2;
          }
        }
      }

      #payChart {
        width: 100%;
        height: 200px;
        background: transparent;
      }
    }
  }
}
</style>
